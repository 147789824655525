.fab {
    position: fixed !important;
    bottom: 40px;
    right: 40px;
}

.bold {
    font-weight: bold;
}

.textTrans {
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: space-between;
}