.page {
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    .content {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        max-width: 400px;
        padding: 30px;
    }

    .logo {
        width: 100%;
        margin-bottom: 40px;
    }

    .headerText {
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 140%;
        color: #130F26;
        text-align: center;
        padding: 0 24px;
        margin-bottom: 4px;
    }

    .subText {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 160%;
        color: #130F26;
        text-align: center;
        padding: 0 24px;
        margin-bottom: 40px;
    }

    .button {
        background-color: #131314;
        border-radius: 20px;
        color: #e3e3e3;
        cursor: pointer;
        font-size: 14px;
        height: 40px;
        letter-spacing: 0.25px;
        outline: none;
        overflow: hidden;
        padding: 0 12px;
        position: relative;
        text-align: center;
        display: flex;
        align-items: center;
    }

    .icon {
        height: 20px;
        margin-right: 12px;
        min-width: 20px;
        width: 20px;
    }
}

.splashScreen {
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #fff;
    z-index: 2000;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.waitingText {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 160%;
    color: #130F26;
    text-align: center;
    padding: 0 24px;
    margin-top: 20px;
}